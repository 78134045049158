import type { Ref } from 'vue'
import type { PayType, OrderDetail } from '@/http/api/checkout/types'
import { ref, onMounted } from 'vue'
import { getOrderDetails, orderFormPost } from "@/http/api/checkout";
import { Toast } from 'vant'

// 获取payList控制参数
const usePayList = () => {
  const activeNames = ref<number[]>([]);
  function changeOpen(index: number) {
    const activeIndex = activeNames.value.findIndex((value) => value === index);
    if (activeIndex > -1) {
      activeNames.value.splice(activeIndex, 1);
    } else {
      activeNames.value.push(index);
    }
  }
  return { activeNames, changeOpen }
}

// 查路径key
const useSearchKey = () => {
  const urlSearch = window.location.search;
  const key = urlSearch ? urlSearch.substring(1) : "";
  return { key }
}

type Order = Omit<OrderDetail, 'payTypes'>
type PayTypes = PayType[]

// 页面需要参数
const usePayTypes = () => {
  const order = ref<Order>({ amount: "0", currencyCode: "" });
  const payTypes = ref<PayTypes>([]);
  return { order, payTypes }
}

// 初始化
function init(order: Ref<Order>, payTypes: Ref<PayTypes>) {
  onMounted(() => {
    const { key } = useSearchKey();
    if (!key) return
    getOrderDetails(key, 1)
      .then((res) => {
        order.value = res
        payTypes.value = res.payTypes;
      })
      .catch(console.error)
  })
}

// 最终提交
function submitHandler(payType: string, checkContent: string, loading: Ref<boolean>) {
  if (!checkContent) {
    Toast('Please select the payment method')
    return
  }
  const params = {
    payType,
    orderNoKey: useSearchKey().key
  }
  loading.value = true
  try {
    orderFormPost(params);
  } catch (err) {
    loading.value = false
  }
}

export { usePayList, useSearchKey, usePayTypes, init, submitHandler }

