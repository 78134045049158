// eslint-disable-next-line
function FormBase(url: string, data: any) {
  const form = document.createElement('form')
  const BASE_URL = process.env.VUE_APP_BASE_API
  form.action = `${BASE_URL}${url}`
  form.method = 'post'
  form.style.display = 'none'
  for (const key in data) {
    const input = document.createElement('input')
    input.name = key
    input.value = data[key]
    form.appendChild(input)
  }
  document.body.appendChild(form)
  return form
}

// eslint-disable-next-line
function syncFormPost(url: string, data: any) {
  FormBase(url, data).submit()
}

export { syncFormPost }